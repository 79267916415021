// Angular
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
// Theming
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Components
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
// Services
import { AuthenticationService } from './http/authentication.service';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';
// Other
import { SharedModule } from '../shared/shared.module';
import { LoaderModule } from '@orthofi/ngx-loader';
import { NotofierModule } from '@orthofi/ngx-notofier';
import { GoogleAnalyticsLibModule } from '@orthofi/ngx-google-tag-manager';

/**
 * `CoreModule` is the home of several singletons, including HTTP services. It should only ever be imported in the `AppModule`
 * and never in any feature modules, otherwise multiple instances of the singletons within would be created. Single use
 * components such as the header can also go in the `CoreModule`.
 */
@NgModule({
  declarations: [
    // Shell
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    // Other
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    BrowserAnimationsModule,
    // Other
    LoaderModule.forRoot(),
    NotofierModule.forRoot(),
    GoogleAnalyticsLibModule.forRoot(),
  ],
  providers: [
    // HTTP: Authentication and global API services
    AuthenticationService,
    // Other: Stand alone services
    DynamicScriptLoaderService,
  ],
  exports: [
    NotFoundComponent,
    LoaderModule
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard. Ensure this module is only ever created once.
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // Other
        DynamicScriptLoaderService
      ]
    };
  }
}
