import { Component } from '@angular/core';

/** The standard OrthoFi footer template. */
@Component( {
  selector: 'of-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
} )
export class FooterComponent {
  /** The current year, used for copyright. */
  year = new Date().getFullYear();
  /** @ignore */
  constructor() { }
}
