<mat-toolbar class="of-header" color="white">
  <mat-toolbar-row class="forms-header__toolbar-row">
    <!-- Right-hand actions -->
    <div class="forms-header__column forms-header__actions forms-header__actions--left-hand not-implemented">
      <mat-icon class="forms-header__menu-icon" alt="Open Sidebar Icon">menu</mat-icon>
    </div>

    <!-- Center image container -->
    <div class="forms-header__column forms-header__title">
      <img
        #logo
        class="forms-header__logo"
        src="https://cdn.orthofi.com/img/logos/logo-2019-blue.svg"
        alt="OrthoFi Logo"
      />
    </div>

    <!-- Right-hand form actions -->
    <div class="forms-header__column forms-header__actions forms-header__actions--right-hand not-implemented" >
      <div class="forms-header__translate" ngClass="forms-header__translate--is-fullsize" >
        <span id="google_translate_element"></span>
      </div>
      <ng-container *ngIf="username">
        <div
          *ngIf="username"
          class="forms-header__user"
          [matMenuTriggerFor]="userMenu"
        >
          <p class="forms-header__username" fxHide.lt-sm>{{ username }}</p>
          <mat-icon class="forms-header__usericon" alt="Account Icon">account_circle</mat-icon>
        </div>
      </ng-container>
    </div>

  </mat-toolbar-row>
</mat-toolbar>

<!-- Account Menu -->
<mat-menu #userMenu="matMenu" class="forms-header__menu">
  <!--
  FIXME: Use this instead. We should use anchors wherever we can, but with the correct text color.
  <button mat-menu-item class="forms-header__goto-dashboard" *ngIf="(patient | async) as patient">
    <a class="button-link-normal" [attr.href]="portalUrl + '/Guardian/Detail/' + patient?.PrimaryGuardianId">Account</a>
  </button>
  -->
  <button mat-menu-item class="forms-header__goto-dashboard">Account</button>
  <button mat-menu-item class="forms-header__logout">Log Out</button>
</mat-menu>
