import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// TODO: Move these to their own files.
/** Configuration regarding which parts of the header should be displayed. */
export interface IHeaderVisibilitySettings {
  /** Should the sidebar menu button be displayed. */
  menuButton: boolean;
  /** Should the username and menu button be displayed. */
  user: boolean;
  /** Should the Google translation select box be displayed. */
  translate: boolean;
  /** Should the logo image be displayed. */
  logo: boolean;
}

/** Configuration regarding the information displayed in the header. */
export interface IPracticeHeadInfo {
  /** The URL of the logo image. */
  image: string;
  /** The name of the practice. */
  practiceName: string;
  /** The name of the practice location. */
  locationName?: string;
}

/**
 * This component will eventually be pulled out in to a layout library. Currently, it only contains
 * work from Amelia trying to figure out what the API/interfaces should look like.
 *
 * A lot of this component should be reworked.
 */
@Component({
  selector: 'of-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnChanges {
  /** The name that will appear next to the user icon. **The user's first name is recommended**. */
  @Input() username?: string;
  /** Which items should appear in the header. */
  @Input() visibility?: IHeaderVisibilitySettings;
  /** A template to use inside the sidebar. */
  @Input() menuButtonCB?: () => {};
  /** We're going to need to know practice information eventually. :x */
  @Input() practice?: IPracticeHeadInfo;

  // Example input setter
  // @Input() set showName(value: boolean) {
  //   this._showName = value;
  //   doSomethingWhenShowNameIsPassed(value);
  // }

  /** Maybe we'll store the state/configuration here? */
  headerData = new BehaviorSubject({
    username: undefined,
    visibility: {
      menuButton: false,
      user: false,
      translate: false,
      logo: true,
    },
    practice: undefined
  });

  /** @ignore */
  constructor( ) { }

  /** @ignore */
  ngOnInit() { }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void { }
}
