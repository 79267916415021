<footer class="of-footer mat-body" fxFlex="">
  <!-- FIXME: We should be able to use color="footer" -->
  <div
    class="of-footer__content"
    fxLayout="column"
    fxLayout.xs="column-reverse"
  >
    <ul
      fxLayout="row wrap" fxLayoutAlign="space-between center" class="of-footer__links" >
      <li fxFlex="auto" fxFlex.xs="50%">
        <a class="of-footer__link" href="https://portal.orthofi.com/Privacy/Index">Online Privacy Notice</a>
      </li>
      <li fxFlex="auto" fxFlex.xs="50%">
        <a class="of-footer__link" href="https://portal.orthofi.com/TermsOfUse/Index">Terms of Use</a>
      </li>
      <li fxFlex="auto" fxFlex.xs="50%">
        <a class="of-footer__link" href="https://portal.orthofi.com/Contact/Index">Contact Us</a>
      </li>
      <li fxFlex="auto" fxFlex.xs="50%">
        <a class="of-footer__link" href="https://orthofi.com/about-us/">About</a>
      </li>
    </ul>
    <p class="of-footer__fineprint">
      <span>&copy; {{ year }} OrthoFi Inc.</span>
      <span>Denver, CO</span>
    </p>
  </div>
</footer>
