// Core
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { LayoutComponent } from './core/components/layout/layout.component';

/** The application's top level routing table. */
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
      children: [
      {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full'
      },
      // Redirect from old links to lazy loaded version.
      {
        path: 'request-password-reset',
        redirectTo: 'password/forgot',
        pathMatch: 'full'
      },
      {
        path: 'password-reset/:token',
        redirectTo: 'password/reset/:token',
        pathMatch: 'full'
      },
      // New, lazy loaded paths
      { path: 'sign-in', loadChildren: () => import('./modules/pages/sign-in/sign-in.module').then((m) => m.SignInModule) },
      { path: 'mfa', loadChildren: () => import('./modules/pages/mfa/mfa.module').then((m) => m.MFAModule) },
      { path: 'password', loadChildren: () => import('./modules/pages/password/password.module').then((m) => m.PasswordModule) },
      { path: 'registration', loadChildren: () =>
          import('./modules/pages/registration/registration.module').then((m) => m.RegistrationModule) },
      { path: '**', component: NotFoundComponent, data: { title: 'Page Not Found' } },
    ],
  }
];

/** The top level application router. */
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
