import { Injectable } from '@angular/core';

// TODO: Document / Window should come from an adapter service.
/** @ignore */
declare let document: any;

/** The metadata about a script that can be loaded into the page. */
export interface IScript {
  /** The short name that will be passed in during invocation of a script. */
  name: string;
  /** The location of the script, which could be a URL or file location. */
  src: string;
}

// TODO: Move into a provider.
/** The index of scripts that will be available to be injected. */
export const ScriptStore: IScript[] = [
  { name: 'googleTranslate', src: '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit' },
  { name: 'stripe', src: 'https://js.stripe.com/v3/' }
];

/**
 * A service for managing external script references and injecting them in to index.html dynamically,
 * alleviating the need hard code every 3rd party script into the file.
 * @see https://stackoverflow.com/questions/34489916/how-to-load-external-scripts-dynamically-in-angular
 */
@Injectable()
export class DynamicScriptLoaderService {

  /** Information describing the state of each script, generated based scripts array passed on initialization. */
  private scripts: any = {};

  /** @ignore */
  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  /**
   * Used to inject multiple scripts in to the underlying page.
   * @param scripts An array of script names that should be injected injected.
   * @example
   * dynamicScriptLoaderService.load(['pendo', 'ga', 'stripe']);
   */
  load(...scripts: string[]): Promise<any[]> {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  /**
   * Inject a script into the underlying page (likely `index.html`) and return a promise indicating when the script has completed loading.
   * @param name The name of the script to be loaded, as defined in lists of scripts inputted during initialization.
   * @example
   * dynamicScriptLoaderService.loadScript('pendo');
   */
  loadScript(name: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              // eslint-disable-next-line no-null/no-null
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0]
          .appendChild(script);
      }
    });
  }
}
