import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsLibService } from '@orthofi/ngx-google-tag-manager';
import { LoaderBusService } from '@orthofi/ngx-loader';

/** The main starting component, containing the top most wrapper and loader.  */
@Component({
  selector: 'of-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /** ignore */
  constructor(
    private _router: Router,
    private _loader: LoaderBusService,
    private _tagManager: GoogleAnalyticsLibService
  ) { }

  ngOnInit(): void {
    // Show the loader while navigating.
    this._loader.forceOnNavigate(this._router);
    // Have Google Analytics follow route changes.
    this._tagManager.emitRouterChanges(this._router);
  }

}
