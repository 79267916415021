import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * The master layout component which is used within the app component to wrap the header, footer, and main sections for the entire app.
 */
@Component({
  selector: 'of-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent { }
