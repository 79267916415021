// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Theming
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
// Components
import { InlineNotificationComponent } from './components/inline-notification/inline-notification.component';
import { ToggleableVisibilityDirective } from './directives/toggleableVisibility.directive';
import { LoaderDisablesFormGroupModule, LoaderTogglesClassModule } from '@orthofi/ngx-loader';
// import { LoaderTogglesClassModule } from '@orthofi/ngx-loader/lib/directives/loader-toggles-class/loader-toggles-class.module';

/**
 * The `SharedModule` is imported in to any feature module where it's children are needed. And, those children are almost
 * always a **dumb** components, directives, and pipes. Generally, `SharedModule` is not imported in to the `AppModule` or `CoreModule`,
 * if you're considering importing the `SharedModule` there to access a child, and the child is single-use, perhaps that child should
 * be moved in to the `CoreModule`.
 *
 * A shared module that needs to be added to a lazy loaded module should **not** have providers, because that would create duplicate
 * service instances. (You could have providers, if duplicate services are intended).
 */
@NgModule({
  declarations: [
    InlineNotificationComponent,
    ToggleableVisibilityDirective
  ],
  imports: [
    // Angular
    CommonModule,
    // Other
    LoaderDisablesFormGroupModule,
    LoaderTogglesClassModule
  ],
  exports: [
    InlineNotificationComponent,
    ToggleableVisibilityDirective,
    // Angular
    CommonModule,
    FlexLayoutModule,
    // App wide Material modules. If a feature module needs a new one, but it's not needed app wide, import it in the feature.
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    // Loader library directives
    LoaderDisablesFormGroupModule,
    LoaderTogglesClassModule
  ]
})
export class SharedModule { } // Don't think about using ModuleWithProviders here until you read this module's docs.
